import { React} from 'react';

import JotFormReact from "../../JotFormReact"

import config from "../../config/config";
import {sendEvent} from "../../helpers"
// import { Navigation } from '../questions/components/navigation';

// Main App component
const RSVPSurvey = (props) => {
  console.log()
  let params = new URL(document.location.toString()).searchParams;
  let experienceID = params.get("experienceID");
  console.log(experienceID);
    const handleSubmit = () => {
      sendEvent('survey_completed', {
        category: 'Survey',
        label: 'RSVP Survey'
    })
    };
  
  var urlToUse;
  if (props.mode == "yes"){
  urlToUse = config.YES_RSVP_URL+"?experienceID="+experienceID;
  }
  else if (props.mode == "freeyes"){
    urlToUse = config.YES_RSVP_FREE_URL+"?experienceID="+experienceID;
  }
  else{
    urlToUse = config.NO_RSVP_URL+"?experienceID="+experienceID
  }
  return (<div>
    <JotFormReact
      formURL={urlToUse}
      onSubmit={handleSubmit}
      autoResize={true} // Enable auto-resizing
    />
  </div>
  )

};

export default RSVPSurvey;