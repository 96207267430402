// /**
//  * Internal Dependencies
//  */
// import metadata from './block.json';
// const { name } = metadata;

// export { name, metadata };

import { registerBlockType } from "@quillforms/blocks";
import display from "./renderer/display";

registerBlockType("mc", {
  supports: {
    editable: true
  },
  attributes: {
    choices: {
      type: "array",
      default: []
    }
  },
  display
});