import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import Landing from './pages/landing/Landing';
import Welcome from './pages/welcome/Welcome';
import Survey from './pages/survey/Survey';
import config from "./config/config";
import RSVP from './pages/rsvp/RSVP';

import RSVPSurvey from './pages/rsvp-survey/RSVPSurvey';

const history = createHistory();
history.listen(location => {
  console.log(window.location)
  var path = window.location.hash ? window.location.hash : window.location.pathname
  var tag = {
    page_location: window.location.href,
    page_path: path
  }
  if(config.SHOULD_TRACK){
    window.gtag('send', 'page_view', tag);
  }
  else{
    console.log(tag)
  }
});

class App extends Component {
  componentDidMount() {
    try {
      console.log(window.location)
      var path = window.location.hash ? window.location.hash : window.location.pathname

      var tag = {
        page_location: window.location.href,
        page_path: path
      }
      if(config.SHOULD_TRACK){
        window.gtag('send', 'page_view', tag);
      }
      else{
        console.log(tag)
      }
    } catch (e) {
      console.error('adBlock InvalidAccessError', e);
    }
  }

  render() {
    return (
      <Router history={history}>
        <div>
          <Switch>
            <Route path="/welcome" component={Welcome} />
            <Route path="/survey" component={Survey} />
            <Route path="/rsvp" component={RSVP} />

            <Route exact path="/" component={Landing} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
