import { registerBlockType } from "@quillforms/blocks";
import display from "./display";

registerBlockType("custom_slider", {
  supports: {
    editable: true
  },
  attributes: {
    start: {
      type: "number",
      default: 1
    },
    end: {
      type: "number",
      default: 7
    },
    startLabel: {
        type: "string",
        default: ""
    },
    endLabel: {
        type: "string",
        default: ""
    }
  },
  display
});