import config from "./config/config";
import GA4React from "ga-4-react";

const ga4react = new GA4React('G-20Z6QMNQ0L'); // Ensure you use your GA4 Measurement ID

export function sendEvent(eventName, eventParams){
    if(config.SHOULD_TRACK){
        ga4react.event(eventName, eventParams)
   
    }
    else{
        console.log("Not sending")
        console.log(eventName)
        console.log(eventParams)
    }
}