import { useEffect, useState} from 'react';
import Swipeable from "react-swipy";

import Card from "./Card";
import Button from "./Button";
const appStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "70vh",
    fontFamily: "sans-serif",
    overflow: "hidden"
  };
  const wrapperStyles = { position: "relative", width: "250px", height: "250px" };
const actionsStyles = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: 12
};
const shuffleArray = (array) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };
  const Cards = () => {
    var cardList = [
        "Which history time would you like to visit?",
        "If you knew that you were going to inherit a fortune, how would your plans for the future change?",
        "What's your dream job?",
        "What's your favorite childhood meal?",
        "If you could have front row seats to any concert, who would you like to see?",
        "If you could master one instrument, which would it be?",
        "What's your proudest accomplishment?",
        "What do you dream your life would be like in 10 years?",
        "What are the most important qualities in friends?",
        "What's your favorite reality TV show?",
        "Who would you like to trade places with for one month?",
        "Who is your favorite professional athlete?",
        "What would you miss most about your home if you moved?",
        "How do you define success?",
        "What's your favorite song to play in the car?",
        "Which is more important, intelligence or common sense?",
        "What's the funniest advice your mother gave you?",
        "What did you get into trouble for when you were young?",
        "What's the scariest movie of all time?",
        "If you could donate one million dollars to charity, which would you choose?",
        "Would you rather meet your great-great-grandparents or your great-great-grandchildren?",
        "What's the worst job you ever had?",
        "Would you rather live by the beach or in the mountains?",
        "If you could do something dangerous just once with no risk, what would you do?",
        "What was your grandmother's signature dish?",
        "What one fear would you like to conquer?",
        "What's your favorite part of Thanksgiving dinner?",
        "What do you wish you were better at saying 'No' to?",
        "How many different places have you lived?",
        "Which of your ancestors would you most like to meet?",
        "If you could work as an assistant to anyone for a year, who would you choose?",
        "What's on your bucket list?",
        "If you could have any view from your back porch, what would it be?",
        "Which book dramatically influenced your life?",
        "Which event in the past, present, or future would you like to witness in person?",
        "What's the strangest thing you have ever eaten?",
        "What was your most memorable meal?",
        "What would you love to find out at a yard sale?",
        "What's the hardest thing you have ever done?",
        "Would you choose to be the worst player on a winning team or the best player on a losing team?",
        "Is science or art more essential to humanity?",
        "What's the most fun party you ever attended?",
        "Which language would you like to speak fluently?",
        "Who taught you how to ride a bike? What's your relationship like with them now?",
        "Who do you think is the most inspirational person alive today?",
        "If you went on a volunteer vacation, who would you most like to help?",
        "What one goal do you hope to accomplish this year?",
        "If you didn't have to worry about money, what would you do in life?",
        "What was your favorite game to play as a child?",
        "Would you rather be a great musician, artist, or athlete?",
        "When you were young, what did you want to be when you grew up?",
        "If you knew you wouldn't get hurt, would you rather skydive or view sharks from an underwater cage?",
        "If you got a tattoo, what would you get and where would you put it?",
        "In which activity would you like a lesson from an expert?",
        "What do you miss about childhood?",
        "Would you rather age forward or backwards from this point?",
        "What's your favorite dessert?",
        "What was the best vacation you ever took?",
        "What's the most beautiful drive you have taken?",
        "What do you most admire about your parents?",
        "If you only had five more years to live, would you change anything about your life?",
        "If you could spend the weekend in any city, which would you choose?",
        "What's your favorite fast food meal?",
        "Do you tend to live in the past, present, or future?",
        "What's the most amazing weather you have seen?",
        "How will our culture change in 100 years?",
        "What pets have you had in your life?",
        "If you could appear on any game show, which would you choose?"
      ];
    const [cards, setCards] = useState([]);
    useEffect(() => {
        // setCards(cardList)
        setCards(shuffleArray(cardList));
      }, []); // Empty dependency array ensures this runs only once on component mount
    

  
      const remove = () =>
      setCards((cards) => cards.slice(1));

      return (
        <div>
          <div style={appStyles}>
            {cards.length > 0 ? (
              <div style={wrapperStyles}>
                <Swipeable
                  buttons={({left, right}) => (
                    <div style={actionsStyles}>
                    <span></span>
                       {/* <Button onClick={left}>Back</Button> */}

                      <Button onClick={right}>Next</Button>
                    </div>
                  )}
                  onAfterSwipe={remove}
                >
                  <Card>{cards[0]}</Card>
                </Swipeable>
                {cards.length > 1 && <Card zIndex={-1}>{cards[1]}</Card>}
              </div>
            ) : (
                <div style={wrapperStyles}>
              <Card zIndex={-2}>No more cards</Card>
              </div>
            )}
          </div>
          <ul id="buttons02" class="buttons">

          <li><a href="/welcome" class="button n01"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 40 40" display="none" width="0" height="0"><path d="M20.9,37L33,25.3c3-3,4.5-5.9,4.5-8.8c0-2.9-0.8-5.1-2.5-6.7c-1.6-1.6-3.9-2.4-6.8-2.4c-0.8,0-1.6,0.1-2.5,0.4 S24.1,8.5,23.3,9c-0.7,0.5-1.3,0.9-1.9,1.3c-0.5,0.4-1,0.9-1.5,1.3c-0.5-0.5-1-0.9-1.5-1.3C18,9.9,17.4,9.5,16.7,9 c-0.7-0.5-1.5-0.9-2.3-1.1s-1.7-0.4-2.5-0.4C9,7.5,6.7,8.3,5,9.9s-2.5,3.8-2.5,6.7c0,0.9,0.2,1.8,0.5,2.7c0.3,0.9,0.7,1.7,1,2.4 c0.4,0.6,0.8,1.3,1.3,1.9c0.5,0.6,0.9,1,1.1,1.3C6.7,25,6.9,25.2,7,25.3L19.1,37c0.2,0.2,0.5,0.4,0.9,0.4S20.6,37.3,20.9,37z"></path></svg><span class="label">Back</span></a></li>
            </ul>
        </div>
      );
    }
  
  export default Cards;