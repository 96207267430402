import { useRef, React, useEffect} from 'react';
import JotFormReact from "../../JotFormReact"

import SurveyForm from './components/survey-form';
import config from "../../config/config";
import GA4React from "ga-4-react";
import {sendEvent} from "../../helpers"
// import { Navigation } from '../questions/components/navigation';

// Main App component
const Survey = () => {

  const handleSubmit = () => {
    sendEvent('survey_completed', {
      category: 'Survey',
      label: 'Matching Survey'
  })
  };
  return (<div>
     {/* Your App Content */}
    <JotFormReact
      formURL={config.SURVEY_URL}
      onSubmit={handleSubmit}
      autoResize={true} // Enable auto-resizing
    />
  </div>)

    // return (
    //     <div>
    //     {/* <JotFormReact
    //     formURL="https://form.jotform.com/242075630199156"/> */}
    //         {/* <Navigation/> */}
    //         {/* <SurveyForm/> */}
    //         <iframe

    //   id="JotFormIFrame-241923614233047"

    //   title="Clone of Brew2024"
    //   // onLoad={alert('Test')}
    //   onload="window.parent.scrollTo(0,0)"

    //   allowtransparency="true"

    //   allow="geolocation; microphone; camera; fullscreen"

    //   src={config.SURVEY_URL}
    //   ref={ref}

    //   frameborder="0"

    //   style={{ display: "block", width: "100vw", height: "90vh",border: "none", scrolling: "no" }}

    //   scrolling="no"

    // >

    //   </iframe><script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>

    //   {/* <script>window.jotformEmbedHandler("iframe[id='JotFormIFrame-242075630199156']", "https://form.jotform.com/")</script> */}
    //   </div>

            

    // );
};

export default Survey;