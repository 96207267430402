import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GA4React from "ga-4-react";

const ga4react = new GA4React("G-20Z6QMNQ0L");

(async () => {
  try {
    await ga4react.initialize()
  } catch (e) {
    console.error('adBlock InvalidAccessError', e);
  }
  //mixpanel.init('20ba3e4766dc46f040add97cbf0dbe49');

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
