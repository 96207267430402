import './Welcome.css';
import { useState } from 'react';
import config from "../../config/config"
import Cards from './Cards';


function Welcome() {
  const [activeTab, setActiveTab] = useState("welcome-section");
  function updateTab(tabName){
    setActiveTab(tabName)
  }
  return <div id="welcome">
    <svg viewBox="0 0 40 40" display="none" width="0" height="0">
        <symbol id="icon-0fab58c8cba7ae847fa5ba32ea5a4bef" viewBox="0 0 40 40">
            <path
                d="M20.9,37L33,25.3c3-3,4.5-5.9,4.5-8.8c0-2.9-0.8-5.1-2.5-6.7c-1.6-1.6-3.9-2.4-6.8-2.4c-0.8,0-1.6,0.1-2.5,0.4 S24.1,8.5,23.3,9c-0.7,0.5-1.3,0.9-1.9,1.3c-0.5,0.4-1,0.9-1.5,1.3c-0.5-0.5-1-0.9-1.5-1.3C18,9.9,17.4,9.5,16.7,9 c-0.7-0.5-1.5-0.9-2.3-1.1s-1.7-0.4-2.5-0.4C9,7.5,6.7,8.3,5,9.9s-2.5,3.8-2.5,6.7c0,0.9,0.2,1.8,0.5,2.7c0.3,0.9,0.7,1.7,1,2.4 c0.4,0.6,0.8,1.3,1.3,1.9c0.5,0.6,0.9,1,1.1,1.3C6.7,25,6.9,25.2,7,25.3L19.1,37c0.2,0.2,0.5,0.4,0.9,0.4S20.6,37.3,20.9,37z">
            </path>
        </symbol>
    </svg>
    <div id="wrapper">
        <div id="main">
            <div class="inner">
                
                <section id="welcome-section" class={activeTab == "welcome-section" ? "active" : "inactive"}  style={activeTab == "welcome-section" ? {} : {display: "none"}}>
                    <div id="image03" class="image" data-position="center"><span class="frame"><img
                                src="img/logo.svg" alt=""/></span></div>
                    <div id="gallery01" class="gallery" style={{opacity: 1}}>
                        <div class="inner">
                            <ul>
                                <li><span class="frame"><img src="img/1.jpg"
                                            alt="Untitled"/></span></li>
                                <li><span class="frame"><img src="img/2.jpg"
                                            alt="Untitled"/></span></li>
                                <li><span class="frame"><img src="img/3.jpg"
                                            alt="Untitled"/></span></li>
                            </ul>
                        </div>
                    </div>
                    <p id="text04"><u><strong>Here’s how the icebreaker game works:</strong></u></p>
                    <p id="text02"><span class="p">
                        1. The person with the closest birthday takes the spotlight! Pick the first question by clicking the “Ice Breakers” button below. Find a good one! <br/> 
                        2. Choose someone and ask them your selected question.<br/> 
                        3. The guest who just responded chooses the next person and asks them another ice breaker question.<br/>
                        Continue reading/answering questions until everyone has answered at least 3 questions or until you’re ready to move on</span></p>
                </section>
                
                <section id="contact-section" class={activeTab == "contact-section" ? "active" : "inactive"}  style={activeTab == "contact-section" ? {} : {display: "none"}}>
                    <h2 id="text03">Contact</h2>
                    <p id="text05">Just text us: (703) 996-9720</p>
                </section>
                <section id="questions-section" class={activeTab == "questions-section" ? "active" : "inactive"} style={activeTab == "questions-section" ? {} : {display: "none"}}>
                    <Cards />
                </section>
                {activeTab != "questions-section" ? 
                <footer id="footer">
                    <hr id="divider01"/>
                    <ul id="buttons02" class="buttons">
                    <li><a href="#questions" onClick={() => updateTab("questions-section")}class="button n01"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 40 40" display="none" width="0" height="0"><path d="M20.9,37L33,25.3c3-3,4.5-5.9,4.5-8.8c0-2.9-0.8-5.1-2.5-6.7c-1.6-1.6-3.9-2.4-6.8-2.4c-0.8,0-1.6,0.1-2.5,0.4 S24.1,8.5,23.3,9c-0.7,0.5-1.3,0.9-1.9,1.3c-0.5,0.4-1,0.9-1.5,1.3c-0.5-0.5-1-0.9-1.5-1.3C18,9.9,17.4,9.5,16.7,9 c-0.7-0.5-1.5-0.9-2.3-1.1s-1.7-0.4-2.5-0.4C9,7.5,6.7,8.3,5,9.9s-2.5,3.8-2.5,6.7c0,0.9,0.2,1.8,0.5,2.7c0.3,0.9,0.7,1.7,1,2.4 c0.4,0.6,0.8,1.3,1.3,1.9c0.5,0.6,0.9,1,1.1,1.3C6.7,25,6.9,25.2,7,25.3L19.1,37c0.2,0.2,0.5,0.4,0.9,0.4S20.6,37.3,20.9,37z"></path></svg><span class="label">Ice Breakers</span></a></li>
                    </ul>
                    {/* <ul id="buttons01" class="buttons">
                        <li><a href="#about" class="button n01" onClick={() => updateTab("about-section")}>About</a></li>
                        <li><a href="#contact" class="button n02" onClick={() => updateTab("contact-section")}>Contact</a></li>
                        <li><a href="#expectations" class="button n03" onClick={() => updateTab("expectations-section")}>What to expect</a></li>
                        <li><a href="#instructions" class="button n03" onClick={() => updateTab("instructions-section")}>Instructions</a></li>
                        <li><a href="#" class="button n04" onClick={() => updateTab("home-section")}>How it works</a></li>
                    </ul> */}
                  
                </footer> : <footer/>}
            </div>
        </div>
    </div>
  </div>
    
}

export default Welcome;
