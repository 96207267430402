/**
 * Internal Dependencies
//  */
// import metadata from './block.json';
// const { name } = metadata;

// export { name, metadata };
import { registerBlockType } from "@quillforms/blocks";
import display from "./renderer/display";

registerBlockType("phone", {
  supports: {
    editable: true
  },
  attributes: {
  },
  display
});