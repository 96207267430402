/**
 * QuillForms Depndencies
 */
import { useTheme, useMessages } from "@quillforms/renderer-core";

/**
 * React Dependencies
 */
import { useEffect } from "react";

/**
 * External Dependencies
 */
import tinyColor from "tinycolor2";
import { css } from "emotion";
import classnames from "classnames";

let timer;
const MyCustomBlockDisplay = (props) => {
  const {
    id,
    attributes,
    setIsValid,
    setIsAnswered,
    setValidationErr,
    isActive,
    val,
    setVal,
    next
  } = props;

  const { required, start, end, startLabel, endLabel } = attributes;
  const messages = useMessages();
  const theme = useTheme();
  const answersColor = tinyColor(theme.answersColor);

  const checkfieldValidation = (value) => {
    if (required === true && (!value || value === "")) {
      setIsValid(false);
      setValidationErr(messages["label.errorAlert.required"]);
    } else {
      setIsValid(true);
      setValidationErr(null);
    }
  };

  const items = [];
  for (let i = start; i <= end; i++) {
    items.push(i);
  }

  useEffect(() => {
    if (!isActive) {
      clearTimeout(timer);
    }
  }, [isActive]);

  useEffect(() => {
    checkfieldValidation(val);
  }, [val]);

  return (
    <div>
    <h2>Thank You!</h2>
    <p>Thank you for filling out the form. We appreciate your time and effort.</p>
  </div>
  );
};
export default MyCustomBlockDisplay;
