import React from "react";



const cardStyles = {
    // background: "linear-gradient(45deg, #5994E4 31%, #D59BCB 67%, #8F88D8 100%)",
    // background: "radial-gradient(circle, #5994E4 30%, #D59BCB 60%, #8F88D8 90%)",

    //best
    // background: "linear-gradient(135deg, #5994E4 25%, #D59BCB 50%, #8F88D8 75%)",
    // background: "linear-gradient(135deg, #81B3FF 25%, #E0B6E1 50%, #B0A8E0 75%)",
    background: "linear-gradient(135deg, #E5D7A6 25%, #F3E2BB 50%, #FFF1D1 75%)",



    borderRadius: 3,
    "font-size": "24px",
  width: "250px",
  height: "250px",
  cursor: "pointer",
  userSelect: "none",
  position: "absolute",
  padding: "20px", // Added padding
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: 0,
  borderRadius: "15px", // Adjusted to make the corners more rounded
  
};

const Card = ({ zIndex = 0, children }) => (
  <div style={{ ...cardStyles, zIndex }}>{children}</div>
);

export default Card;
