import React from 'react';
import { Form, useFieldAnswer } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import validator from 'validator';
// import { questions } from '../../../config/questions';

import "../../../components/slider";
import "../../../components/thankyou";
import "../../../components/phone";
import "../../../components/multiple-choice";

import axios from "axios";
import config from "../../../config/config";

registerCoreBlocks();

const SurveyForm = () => {
  const age = (dateString) => {
    let birth = new Date(dateString);
    let now = new Date();
    let beforeBirth = (birth.setDate(now.getDate()), birth.setMonth(now.getMonth()), birth.getTime()) < birth.getTime() ? 0 : 1;
    return now.getFullYear() - birth.getFullYear() - beforeBirth;
  };

  const kidsAnswerQuestion = useFieldAnswer("kids");
  const questions =
  [
    {name: "multiple-choice", id: "choosecity", attributes: {description: "", required: true, label: "Where will you be joining us?", choices: [{label: "Charlottesville (VA)", value: "Charlottesville (VA)"}, {label: " Seattle (WA)", value: " Seattle (WA)"}], max: 1, multiple: false, min: 1}},
    {name: "phone", id: "phonenumber-phonenumber", attributes: {description: "", required: true, label: "Please enter your phone number"}},
    {name: "short-text", id: "firstname", attributes: {description: "", required: true, label: "Please enter your first name"}},
    {name: "short-text", id: "lastname", attributes: {description: "", required: true, label: "Please enter your last name"}},
    {name: "short-text", id: "email", attributes: {description: "", required: true, label: "Please enter your email"}},
    {name: "custom_slider", id: "careerpriority", attributes: {startLabel: "Strongly Disagree", endLabel: " Strongly Agree", description: "", classnames: "first-block", required: true, label: "Career is my top priority"}},
    {name: "custom_slider", id: "familypriority", attributes: {startLabel: "Strongly Disagree", endLabel: " Strongly Agree", description: "", classnames: "first-block", required: true, label: "Family is my top priority"}},
    {name: "custom_slider", id: "faithpriority", attributes: {startLabel: "Strongly Disagree", endLabel: " Strongly Agree", description: "", classnames: "first-block", required: true, label: "Faith is my top priority"}},
    {name: "custom_slider", id: "Sponaneous", attributes: {startLabel: "Strongly Disagree", endLabel: " Strongly Agree", description: "", classnames: "first-block", required: true, label: "I'm spontaneous"}},
    {name: "custom_slider", id: "Selfdisciplined", attributes: {startLabel: "Strongly Disagree", endLabel: " Strongly Agree", description: "", classnames: "first-block", required: true, label: "I'm self-disciplined"}},
    {name: "custom_slider", id: "Extraverted", attributes: {startLabel: "Strongly Disagree", endLabel: " Strongly Agree", description: "", classnames: "first-block", required: true, label: "I'm extraverted"}},
    {name: "custom_slider", id: "Agreeable", attributes: {startLabel: "Strongly Disagree", endLabel: " Strongly Agree", description: "", classnames: "first-block", required: true, label: "I'm agreeable"}},
    {name: "custom_slider", id: "Stressed", attributes: {startLabel: "Strongly Disagree", endLabel: " Strongly Agree", description: "", classnames: "first-block", required: true, label: "I'm often stressed"}},
    {name: "custom_slider", id: "conversationlead", attributes: {startLabel: "Strongly Disagree", endLabel: " Strongly Agree", description: "", classnames: "first-block", required: true, label: "I often take the initiative to keep the conversation flowing"}},
    {name: "custom_slider", id: "politicalconvo", attributes: {startLabel: "Strongly Disagree", endLabel: " Strongly Agree", description: "", classnames: "first-block", required: true, label: "I enjoy discussing politics and/or the news"}},
    {name: "custom_slider", id: "Humor", attributes: {startLabel: "Strongly Disagree", endLabel: " Strongly Agree", description: "", classnames: "first-block", required: true, label: "I can often make others laugh"}},
    {name: "multiple-choice", id: "Humortype", attributes: {description: "Choose up to 2", required: true, label: "I typically like jokes that are..", choices: [{label: "Politically incorrect", value: "Politically incorrect"}, {label: " Punny", value: " Punny"}, {label: " Observational", value: " Observational"}, {label: " Self-deprecating", value: " Self-deprecating"}, {label: " Slapstick", value: " Slapstick"}, {label: " Dark", value: " Dark"}], max: 2, multiple: true, min: 1}},
    {name: "multiple-choice", id: "musicinterest", attributes: {description: "Choose up to 2", required: true, label: "What type of music do you listen to?", choices: [{label: "Alternative/indie", value: "Alternative/indie"}, {label: " Classical/instrumental", value: " Classical/instrumental"}, {label: " Country", value: " Country"}, {label: " Jazz", value: " Jazz"}, {label: " Pop", value: " Pop"}, {label: " Classic rock", value: " Classic rock"}, {label: " Heavy metal", value: " Heavy metal"}, {label: " Electronic", value: " Electronic"}, {label: " Hip-hop/rap", value: " Hip-hop/rap"}, {label: " Trap/drill", value: " Trap/drill"}, {label: " R&B", value: " R&B"}, {label: " Reggae", value: " Reggae"}, {label: " Reggaeton", value: " Reggaeton"}, {label: " Top 40/what's on the radio", value: " Top 40/what's on the radio"}, {label: " I don't really care", value: " I don't really care"}, {label: " Other", value: " Other"}], max: 2, multiple: true, min: 1}},
    {name: "multiple-choice", id: "oftenwanthangfriends", attributes: {description: "", required: true, label: "How often do you hang out with friends?", choices: [{label: "Once per month", value: "Once per month"}, {label: " Bi-weekly", value: " Bi-weekly"}, {label: " Once a week", value: " Once a week"}, {label: " Two-Three times per week", value: " Two-Three times per week"}, {label: " Daily", value: " Daily"}, {label: " Other", value: " Other"}], max: 1, multiple: false, min: 1}},
    {name: "multiple-choice", id: "whatget", attributes: {description: "Choose as many as you like", required: false, label: "What are you hoping to get out of this experience?", choices: [{label: "Meet couples", value: "Meet couples"}, {label: " Meet a new friend", value: " Meet a new friend"}, {label: " Make a professional connection", value: " Make a professional connection"}, {label: " Make a romantic connection", value: " Make a romantic connection"}, {label: " Explore new places (e.g. venues other than restaurants)", value: " Explore new places (e.g. venues other than restaurants)"}, {label: " Not sure", value: " Not sure"}], max: 6, multiple: true, min: 0}},
    {name: "multiple-choice", id: "willingspendfriends", attributes: {description: "", required: true, label: "How much are you willing to spend when out with friends?", choices: [{label: "Less than $25", value: "Less than $25"}, {label: " $25 to $50", value: " $25 to $50"}, {label: " $50+", value: " $50+"}], max: 1, multiple: false, min: 1}},
    {name: "multiple-choice", id: "foodpreferences", attributes: {description: "Choose as many as you like", required: true, label: "What does your perfect menu include?", choices: [{label: "Fish", value: "Fish"}, {label: " Vegitarian", value: " Vegitarian"}, {label: " Meat", value: " Meat"}, {label: " Vegan", value: " Vegan"}, {label: " Dairy Free", value: " Dairy Free"}, {label: " Gluten Free", value: " Gluten Free"}], max: 6, multiple: true, min: 1}},
    {name: "multiple-choice", id: "relationshipstatus", attributes: {description: "", required: true, label: "What's your relationship status?", choices: [{label: "Single", value: "Single"}, {label: " It's complicated", value: " It's complicated"}, {label: " In a Relationship", value: " In a Relationship"}, {label: " Married", value: " Married"}], max: 1, multiple: false, min: 1}},
    {name: "multiple-choice", id: "kids", attributes: {description: "", required: false, label: "Do you have kids?", choices: [{label: "Yes", value: "Yes"}, {label: " No", value: " No"}], max: 1, multiple: false, min: 0}},
    ...(kidsAnswerQuestion?.includes("Yes") ? [
    {name: "multiple-choice", id: "kidsage", attributes: {description: "Choose up to 8", required: false, label: "What are their age ranges?", choices: [{label: "Infants (0-2 years old)", value: "Infants (0-2 years old)"}, {label: " Pre-schoolers (3-5 years old)", value: " Pre-schoolers (3-5 years old)"}, {label: " Early Childhood (6-8 years old)", value: " Early Childhood (6-8 years old)"}, {label: " Middle Childhood (9-11 years old)", value: " Middle Childhood (9-11 years old)"}, {label: " Adolescents (12-18 years old)", value: " Adolescents (12-18 years old)"}, {label: " Young Adults (19-25 years old)", value: " Young Adults (19-25 years old)"}, {label: " Grown Adults (25+ years old)", value: " Grown Adults (25+ years old)"}, {label: " Wide range of kids (10+ year gap)", value: " Wide range of kids (10+ year gap)"}], max: 8, multiple: true, min: 0}}]: [])
    ,
    {name: "multiple-choice", id: "workindustry", attributes: {description: "", required: true, label: "What industry do you work in?", choices: [{label: "Agriculture", value: "Agriculture"}, {label: " Architecture and Planning", value: " Architecture and Planning"}, {label: " Arts", value: " Arts"}, {label: " Entertainment", value: " Entertainment"}, {label: " Automotive", value: " Automotive"}, {label: " Banking and Finance", value: " Banking and Finance"}, {label: " Construction", value: " Construction"}, {label: " Education", value: " Education"}, {label: " Energy", value: " Energy"}, {label: " Food and Beverage", value: " Food and Beverage"}, {label: " Government and Public Administration", value: " Government and Public Administration"}, {label: " Healthcare and Social Assistance", value: " Healthcare and Social Assistance"}, {label: " Hospitality and Tourism", value: " Hospitality and Tourism"}, {label: " Information Technology (IT)", value: " Information Technology (IT)"}, {label: " Legal", value: " Legal"}, {label: " Manufacturing", value: " Manufacturing"}, {label: " Marketing and Advertising", value: " Marketing and Advertising"}, {label: " Media and Communications", value: " Media and Communications"}, {label: " Nonprofit and Philanthropy", value: " Nonprofit and Philanthropy"}, {label: " Real Estate", value: " Real Estate"}, {label: " Retail", value: " Retail"}, {label: " Science and Research", value: " Science and Research"}, {label: " Telecommunications", value: " Telecommunications"}, {label: " Transportation and Logistics", value: " Transportation and Logistics"}, {label: " Utilities", value: " Utilities"}, {label: " Other", value: " Other"}], max: 1, multiple: false, min: 1}},
    {name: "date", id: "whenmove", attributes: {description: "Doesn't need to exact...within a month is fine.", required: false, label: "When did you move to this city?"}},
    {name: "multiple-choice", id: "languagefluent", attributes: {description: "", required: true, label: "What language do you speak fluently?", choices: [{label: "English", value: "English"}, {label: " Arabic", value: " Arabic"}, {label: " Bengali", value: " Bengali"}, {label: " French", value: " French"}, {label: " German", value: " German"}, {label: " Hindi", value: " Hindi"}, {label: " Indonesian", value: " Indonesian"}, {label: " Japanese", value: " Japanese"}, {label: " Mandarin Chinese", value: " Mandarin Chinese"}, {label: " Marathi", value: " Marathi"}, {label: " Portuguese", value: " Portuguese"}, {label: " Russian", value: " Russian"}, {label: " Spanish", value: " Spanish"}, {label: " Swahili", value: " Swahili"}, {label: " Tamil", value: " Tamil"}, {label: " Telugu", value: " Telugu"}, {label: " Turkish", value: " Turkish"}, {label: " Urdu", value: " Urdu"}, {label: " Vietnamese", value: " Vietnamese"}, {label: " Other", value: " Other"}], max: 1, multiple: false, min: 1}},
    {name: "multiple-choice", id: "religiousaffiliation", attributes: {description: "Choose as many as you like", required: true, label: "What religious affiliation(s) do you identify with?", choices: [{label: "Agnostic", value: "Agnostic"}, {label: " Atheist", value: " Atheist"}, {label: " Buddhist", value: " Buddhist"}, {label: " Christian", value: " Christian"}, {label: " Hindu", value: " Hindu"}, {label: " Jain", value: " Jain"}, {label: " Jewish", value: " Jewish"}, {label: " Muslim", value: " Muslim"}, {label: " Zoroastrian", value: " Zoroastrian"}, {label: " Sikh", value: " Sikh"}, {label: " Spiritual", value: " Spiritual"}, {label: " Prefer not to say", value: " Prefer not to say"}, {label: " Other", value: " Other"}], max: 13, multiple: true, min: 1}},
    {name: "custom_slider", id: "politicalspectrum", attributes: {startLabel: "Liberal", endLabel: " Conservative", description: "", classnames: "first-block", required: false, label: "Where do you fall in the political spectrum?"}},
    {name: "multiple-choice", id: "genderidentity", attributes: {description: "", required: true, label: "What's your gender identity", choices: [{label: "Male", value: "Male"}, {label: " Female", value: " Female"}, {label: " Nonbinary", value: " Nonbinary"}], max: 1, multiple: false, min: 1}},
    {name: "date", id: "birthday", attributes: {description: "We want to confirm you are over 18 years old.", required: true, label: "What year were you born?"}},
    {name: "multiple-choice", id: "ethnicidentity", attributes: {description: "", required: true, label: "What ethnicity(s) do you identify with?", choices: [{label: "White/Caucasian", value: "White/Caucasian"}, {label: " Black", value: " Black"}, {label: " Latino/Hispanic/Spanish origin", value: " Latino/Hispanic/Spanish origin"}, {label: " Middle Eastern/North African", value: " Middle Eastern/North African"}, {label: " African", value: " African"}, {label: " East Asian", value: " East Asian"}, {label: " Southeast Asian", value: " Southeast Asian"}, {label: " South Asian", value: " South Asian"}, {label: " Native Hawaiian/Pacific Islander", value: " Native Hawaiian/Pacific Islander"}, {label: " Native American", value: " Native American"}, {label: " Mixed Race", value: " Mixed Race"}, {label: " Other", value: " Other"}], max: 1, multiple: false, min: 1}},
    {name: "short-text", id: "university", attributes: {description: "", required: false, label: "What university did/do you attend, if any?"}},
    {name: "custom_slider", id: "lonely", attributes: {startLabel: "Never", endLabel: " Everyday", description: "", classnames: "first-block", required: true, label: "How often do you feel lonely?"}},
    {name: "short-text", id: "howhear", attributes: {description: "If through a friend, what's their name?", required: true, label: "How did you hear about us?"}},
    {name: "phone", id: "phonenumber-bestfriendphone1", attributes: {description: "Leave your best friend's number, and we'll give them priority when we match you to a dinner. Friends of friends is a great way to meet people!", required: false, label: "Best Friend's Phone Number #1"}},
    {name: "phone", id: "phonenumber-bestfriendphone2", attributes: {description: "Leave your best friend's number, and we'll give them priority when we match you to a dinner. Friends of friends is a great way to meet people!", required: false, label: "Best Friend's Phone Number #2"}},
    {name: "multiple-choice", id: "uvaconsent", attributes: {description: " ", required: true, label: "We are working with the Emotion and Behavior Lab at UVA to best match you with your dinner group. Do we additionally have your consent to release your de-identified data to the Emotion and Behavior Lab be analyzed? We will NOT share your individual data with anyone else, and only aggregated results of the study will be reported in peer-reviewed scientific articles.", choices: [{label: "I consent", value: "I consent"}, {label: " I do not consent", value: " I do not consent"}], max: 1, multiple: false, min: 1}},
    ]
    
    
    
    
    
  // const beforeNextFunction = (
  //   setIsFieldValid,
  //   setIsPending,
  //   currentBlockId,
  //   answers,
  //   setFieldValidationErr,
  //   setIsCurrentBlockSafeToSwipe,
  //   goToBlock,
  //   goNext
  // ) => {
  //   console.log(currentBlockId);
  // };

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId="1"
        beforeGoingNext={({
          setIsFieldValid,
          setIsPending,
          currentBlockId,
          answers,
          setFieldValidationErr,
          setIsCurrentBlockSafeToSwipe,
          goToField,
          goNext
        }) => {
          console.log(currentBlockId);
          if (currentBlockId.includes("phonenumber-")) {
            console.log(answers);
            const currentAnswer = "" + answers[currentBlockId]["value"];
            console.log(currentAnswer);
            console.log(currentAnswer == undefined)
            const isValidPhone = validator.isMobilePhone(currentAnswer, ["en-US"]);
            console.log(isValidPhone);
            if (isValidPhone ||  answers[currentBlockId]["isAnswered"] == false) {
              setIsFieldValid(currentBlockId, true);
              setFieldValidationErr(currentBlockId, "");
              setIsCurrentBlockSafeToSwipe(true);
              goNext();
            } else {
              setIsFieldValid(currentBlockId, false);
              setFieldValidationErr(currentBlockId, "Please enter a valid phone number");
            }
          } else if (currentBlockId === "birthday") {
            const currentAnswer = "" + answers[currentBlockId]["value"];
            const currentAge = age(currentAnswer);
            console.log(currentAge);
            if (currentAge >= 18) {
              setIsFieldValid(currentBlockId, true);
              setFieldValidationErr(currentBlockId, "");
              setIsCurrentBlockSafeToSwipe(true);
              goNext();
            } else {
              setIsFieldValid(currentBlockId, false);
              setFieldValidationErr(currentBlockId, "Sorry! You must be 18+");
            }
          }
          else if(currentBlockId == "email"){
            const email = "" + answers[currentBlockId]["value"];
            const isValidEmail = validator.isEmail(email)
            if (isValidEmail) {
              setIsFieldValid(currentBlockId, true);
              setFieldValidationErr(currentBlockId, "");
              setIsCurrentBlockSafeToSwipe(true);
              goNext();
            } else {
              setIsFieldValid(currentBlockId, false);
              setFieldValidationErr(currentBlockId, "Please enter a valid email");
            }

          }
          else {
            setIsFieldValid(currentBlockId, true);
            setFieldValidationErr(currentBlockId, "");
            setIsCurrentBlockSafeToSwipe(true);
            goNext();
          }
        }}
        formObj={{ blocks: questions }}
        onSubmit={(data, { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }) => {
          const answers = data["answers"];
          if (answers["uvaconsent"]["value"] === undefined) {
            setIsSubmitting(false);
            return;
          }
          // for (const [key, value] of Object.entries(answers)) {
          //   if (value["value"] === undefined) {
              
          //   }
          // }
          setIsSubmitting(true);
          axios.post(`${config.API_HOST}/results`, data)
            .then(response => {
              setIsSubmitting(false);
              completeForm();
            })
            .catch(error => {
              console.log(error);
              setIsSubmitting(false);
              setSubmissionErr(error.message);
            });
          console.log("SUBMITTED FOR GOOD");
        }}
      />
    </div>
  );
};

export default SurveyForm;
