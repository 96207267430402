import './Landing.css';
import { useState } from 'react';
import config from "../../config/config"


function Landing() {
  const [activeTab, setActiveTab] = useState("home-section");
  function updateTab(tabName){
    setActiveTab(tabName)
  }
  return <div id="landing">
    <svg viewBox="0 0 40 40" display="none" width="0" height="0">
        <symbol id="icon-0fab58c8cba7ae847fa5ba32ea5a4bea" viewBox="0 0 40 40">
            <path
                d="M20.9,37L33,25.3c3-3,4.5-5.9,4.5-8.8c0-2.9-0.8-5.1-2.5-6.7c-1.6-1.6-3.9-2.4-6.8-2.4c-0.8,0-1.6,0.1-2.5,0.4 S24.1,8.5,23.3,9c-0.7,0.5-1.3,0.9-1.9,1.3c-0.5,0.4-1,0.9-1.5,1.3c-0.5-0.5-1-0.9-1.5-1.3C18,9.9,17.4,9.5,16.7,9 c-0.7-0.5-1.5-0.9-2.3-1.1s-1.7-0.4-2.5-0.4C9,7.5,6.7,8.3,5,9.9s-2.5,3.8-2.5,6.7c0,0.9,0.2,1.8,0.5,2.7c0.3,0.9,0.7,1.7,1,2.4 c0.4,0.6,0.8,1.3,1.3,1.9c0.5,0.6,0.9,1,1.1,1.3C6.7,25,6.9,25.2,7,25.3L19.1,37c0.2,0.2,0.5,0.4,0.9,0.4S20.6,37.3,20.9,37z">
            </path>
        </symbol>
    </svg>
    <div id="wrapper">
        <div id="main">
            <div class="inner">
                <section id="home-section" class={activeTab == "home-section" ? "active" : "inactive"}  style={activeTab == "home-section" ? {} : {display: "none"}}>
                    <div id="image03" class="image" data-position="center"><span class="frame"><img
                                src="img/logo.svg" alt=""/></span></div>
                    <div id="gallery01" class="gallery" style={{opacity: 1}}>
                        <div class="inner">
                            <ul>
                                <li><span class="frame"><img src="img/1.jpg"
                                            alt="Untitled"/></span></li>
                                <li><span class="frame"><img src="img/2.jpg"
                                            alt="Untitled"/></span></li>
                                <li><span class="frame"><img src="img/3.jpg"
                                            alt="Untitled"/></span></li>
                            </ul>
                        </div>
                    </div>
                    <p id="text04"><u><strong>Here's how it works:</strong></u></p>
                    <p id="text02"><span class="p">1. Complete the survey below!<br/> 2. Get matched with a group of
                            people for a dinner at a local restaurant.<br/> 3. After dinner, continue the night with
                            other groups at an optional post-dinner experience :)</span></p>
                </section>
                {/* <section id="instructions-section" class={activeTab == "instructions-section" ? "active" : "inactive"}  style={activeTab == "instructions-section" ? {} : {display: "none"}}>
                    <div id="image03" class="image" data-position="center"><span class="frame"><img
                                src="img/logo.svg" alt=""/></span></div>
                    <div id="gallery01" class="gallery" style={{opacity: 1}}>
                        <div class="inner">
                            <ul>
                                <li><span class="frame"><img src="img/1.jpg"
                                            alt="Untitled"/></span></li>
                                <li><span class="frame"><img src="img/2.jpg"
                                            alt="Untitled"/></span></li>
                                <li><span class="frame"><img src="img/3.jpg"
                                            alt="Untitled"/></span></li>
                            </ul>
                        </div>
                    </div>
                    <p id="text04"><u><strong>Here’s how the icebreaker game works:</strong></u></p>
                    <p id="text02"><span class="p">
                        1. The one with the closest birthday takes the spotlight! Pick a question from the candle box in the middle and share it with the table.<br/> 
                        2. Choose your partner in crime! Decide who gets the honor of answering your selected question.<br/> 
                        3. The person who just responded to the question chooses the next question from the middle, reads it, and chooses the person they want to answer their question.<br/>
                        4. Continue reading/answering questions until everyone has answered at least 3 questions or until you’re ready to move on</span></p>
                </section> */}
                <section id="about-section" class={activeTab == "about-section" ? "active" : "inactive"} style={activeTab == "about-section" ? {} : {display: "none"}}>
                    <div id="image02" class="image" data-position="center"><span class="frame"><img
                                src="img/about.jpg" alt=""/></span></div>
                    <h2 id="text07">About</h2>
                    <p id="text08"><span class="p">We're Kostas &amp; Mark. After meeting in a coffee shop, we
                            discovered a common struggle: making friends as an adult is hard - harder than it needs to
                            be. We believe it should should be less awkward and more authentic.</span><span
                            class="p">That's why we started Denizen. We match you with curated experiences and awesome
                            people in your city. A huge thanks to our partners at UVA Emotion &amp; Behavior Lab for
                            providing guidance on our matching algorithm.</span></p>
                </section>
                <section id="contact-section" class={activeTab == "contact-section" ? "active" : "inactive"}  style={activeTab == "contact-section" ? {} : {display: "none"}}>
                    <h2 id="text03">Contact</h2>
                    <p id="text05">Just text us: (703) 996-9720</p>
                </section>
                <section id="expectations-section" class={activeTab == "expectations-section" ? "active" : "inactive"} style={activeTab == "expectations-section" ? {} : {display: "none"}}>
                    <div id="image01" class="image" data-position="center"><span class="frame"><img
                                src="img/expectations.jpg" alt=""/></span></div>
                    <p id="text09"><span class="p"><strong>What to expect</strong><br/> (once you've submitted your
                            survey)</span></p>
                    <p id="text10">1. If selected, you'll receive a link via text to sign up! Be on the lookout :)</p>
                    <p id="text11">2. Experiences will be at a small dining table, at a local restaurant, with your
                        closest matches.</p>
                    <p id="text12">3. We'll let you know the day/time of the dinner, but details about the location will
                        NOT be shared until 48 hours before the experience.</p>
                    <p id="text13">4. Details about other members will NEVER be shared before the experience.</p>
                    <p id="text14">5. Each experience will have a small ticket price. This ticket exists to support our
                        mission &amp; the cost of running these experiences (i.e. table games, ice-breakers, fun
                        surprises, etc.). it does not include the cost of food or drinks.</p>
                    <p id="text15">6. Each member is permitted <u>ONE</u> plus one. They are guaranteed to sit at your
                        table.</p>
                    <p id="text16">7. Dinner confirmations are final.</p>
                    <p id="text06">8. Since each group is uniquely designed, no-shows without prior notice can result in
                        being banned from future experiences. Please notify us 24 hours prior to the event!</p>
                </section>
                <footer id="footer">
                    <hr id="divider01"/>
                    <ul id="buttons02" class="buttons">
                    <li><a href="/survey" class="button n01"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 40 40" display="none" width="0" height="0"><path d="M20.9,37L33,25.3c3-3,4.5-5.9,4.5-8.8c0-2.9-0.8-5.1-2.5-6.7c-1.6-1.6-3.9-2.4-6.8-2.4c-0.8,0-1.6,0.1-2.5,0.4 S24.1,8.5,23.3,9c-0.7,0.5-1.3,0.9-1.9,1.3c-0.5,0.4-1,0.9-1.5,1.3c-0.5-0.5-1-0.9-1.5-1.3C18,9.9,17.4,9.5,16.7,9 c-0.7-0.5-1.5-0.9-2.3-1.1s-1.7-0.4-2.5-0.4C9,7.5,6.7,8.3,5,9.9s-2.5,3.8-2.5,6.7c0,0.9,0.2,1.8,0.5,2.7c0.3,0.9,0.7,1.7,1,2.4 c0.4,0.6,0.8,1.3,1.3,1.9c0.5,0.6,0.9,1,1.1,1.3C6.7,25,6.9,25.2,7,25.3L19.1,37c0.2,0.2,0.5,0.4,0.9,0.4S20.6,37.3,20.9,37z"></path></svg><span class="label">Reserve a Spot</span></a></li>

                    {/* <li><a href="https://oq4nnn9fry0.typeform.com/to/gqw8FJrg" class="button n01"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 40 40" display="none" width="0" height="0"><path d="M20.9,37L33,25.3c3-3,4.5-5.9,4.5-8.8c0-2.9-0.8-5.1-2.5-6.7c-1.6-1.6-3.9-2.4-6.8-2.4c-0.8,0-1.6,0.1-2.5,0.4 S24.1,8.5,23.3,9c-0.7,0.5-1.3,0.9-1.9,1.3c-0.5,0.4-1,0.9-1.5,1.3c-0.5-0.5-1-0.9-1.5-1.3C18,9.9,17.4,9.5,16.7,9 c-0.7-0.5-1.5-0.9-2.3-1.1s-1.7-0.4-2.5-0.4C9,7.5,6.7,8.3,5,9.9s-2.5,3.8-2.5,6.7c0,0.9,0.2,1.8,0.5,2.7c0.3,0.9,0.7,1.7,1,2.4 c0.4,0.6,0.8,1.3,1.3,1.9c0.5,0.6,0.9,1,1.1,1.3C6.7,25,6.9,25.2,7,25.3L19.1,37c0.2,0.2,0.5,0.4,0.9,0.4S20.6,37.3,20.9,37z"></path></svg><span class="label">Reserve a Spot</span></a></li> */}
                    </ul>
                    <ul id="buttons01" class="buttons">
                        <li><a href="#about" class="button n01" onClick={() => updateTab("about-section")}>About</a></li>
                        <li><a href="#contact" class="button n02" onClick={() => updateTab("contact-section")}>Contact</a></li>
                        <li><a href="#expectations" class="button n03" onClick={() => updateTab("expectations-section")}>What to expect</a></li>
                        {/* <li><a href="#instructions" class="button n03" onClick={() => updateTab("instructions-section")}>Instructions</a></li> */}
                        <li><a href="#" class="button n04" onClick={() => updateTab("home-section")}>How it works</a></li>
                    </ul>
                  
                </footer>
            </div>
        </div>
    </div>
  </div>
    
}

export default Landing;
