

const dev = {
    // API_HOST: "http://localhost:3000",
    // API_HOST: "http://localhost:3000",
    // SURVEY_URL: "https://form.jotform.com/242137091254047",
    // SURVEY_ID: "242137091254047",
    // RSVP_ID: "https://jotform.com/242143553240042?phoneNumber[full]=7576201611",
    API_HOST: "https://api.denizen.community",
    YES_RSVP_URL: "https://form.jotform.com/242143553240042",
    NO_RSVP_URL: "https://form.jotform.com/242146154564152",

    YES_RSVP_FREE_URL: "https://form.jotform.com/242643597616163",

    SURVEY_URL: "https://form.jotform.com/242075630199156",

    SHOULD_TRACK: false,
    siteConfig: {

    },
    infos : [
        {
          title: "Empower your projects",
          description:
            "Unlock the full potential of your projects with our open-source SaaS platform. Collaborate seamlessly, innovate effortlessly, and scale limitlessly.",
          image: "/images/work-from-home.jpg",
          list: [
            {
              title: "Collaborative",
              description: "Work together with your team members in real-time.",
              icon: "laptop",
            },
            {
              title: "Innovative",
              description: "Stay ahead of the curve with access constant updates.",
              icon: "settings",
            },
            {
              title: "Scalable",
              description:
                "Our platform offers the scalability needed to adapt to your needs.",
              icon: "search",
            },
          ],
        },
        {
          title: "Why Choose Our Dinner Events?",
          description:
            "Integrate our open-source SaaS seamlessly into your existing workflows. Effortlessly connect with your favorite tools and services for a streamlined experience.",
          image: "/images/work-from-home.jpg",
          list: [
            {
              title: "Curated Guest Lists",
              description:
                "We thoughtfully match you with guests based on shared interests and diverse backgrounds, ensuring engaging and memorable conversations.",
              icon: "laptop",
            },
            {
              title: "Easy Reservations",
              description: "Our seamless sign-up process and flexible scheduling make it easy for you to join a dinner that fits your calendar.",
              icon: "search",
            },
            {
              title: "Lasting Connections",
              description:
                "Form meaningful relationships with interesting people, expanding your social and professional network in a relaxed setting.",
              icon: "settings",
            },
          ],
        },
      ]
};

const prod = {
    API_HOST: "https://api.denizen.community",
    // API_HOST: "https://popupdinner.robpetri.com",
    SHOULD_TRACK: true,
    SURVEY_URL: "https://form.jotform.com/242075630199156",
    YES_RSVP_URL: "https://form.jotform.com/242143553240042",
    YES_RSVP_FREE_URL: "https://form.jotform.com/242643597616163",
    NO_RSVP_URL: "https://form.jotform.com/242146154564152",

    siteConfig: {
        
    }

};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
    ...config
};